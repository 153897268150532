<template>
  <div>
    <div class="bottom-title flex flex-evenly">
      <div style="" class="flex">
        <div>
          <img
            src="https://cdn.bubbleplan.cn/static/home/logo.png"
            alt=""
            style="width: 78px; height: 80px"
          />
        </div>
        <div style="margin-left: 24px">
          <div
            style="
              margin-top: 8px;
              color: #ffffff;
              font-size: 21px;
              line-height: 29.75px;
            "
          >
            泡泡计划
          </div>
          <div
            style="
              margin-top: 8px;
              color: #999999;
              font-size: 19px;
              line-height: 26.92px;
            "
          >
            Bubble Plan
          </div>
        </div>
      </div>

      <div>
        <div class="bottom-title-title">客服</div>
        <div class="bottom-title-text" @click="contactus()">联系我们</div>
        <div class="bottom-title-text" @click="problemFeedback()">问题反馈</div>
      </div>
      <div>
        <div class="bottom-title-title">招聘</div>
        <div class="bottom-title-text" @click="recruit()">相关</div>
      </div>
      <div class="flex">
        <div
          class="bottom-title-text"
          style="display: inline-block"
          @click="
            skip(
              'https://www.douyin.com/user/MS4wLjABAAAAHzUqAlTLfiBKH7RYr6lfWFxMXP6NoqQcMo7gDrQsaTQ'
            )
          "
        >
          <img
            src="https://cdn.bubbleplan.cn/static/home/dy.png"
            alt=""
            style="width: 39.11px; height: 39.11px; margin-right: 40px"
          />
        </div>
        <div
          class="bottom-title-text"
          style="display: inline-block"
          @click="skip('https://weibo.com/u/7931073970')"
        >
          <img
            src="https://cdn.bubbleplan.cn/static/home/wb.png"
            alt=""
            style="width: 39.11px; height: 30.08px; margin-right: 40px"
          />
        </div>
        <div
          class="bottom-title-text"
          style="display: inline-block"
          @click="
            skip(
              'https://www.xiaohongshu.com/user/profile/668935d7000000000f03483d'
            )
          "
        >
          <img
            src="https://cdn.bubbleplan.cn/static/home/xhs.png"
            alt=""
            style="width: 36.1px; height: 36.1px; margin-right: 40px"
          />
        </div>
        <div
          class="bottom-title-text"
          style="display: inline-block"
          @click="skip('https://discord.gg/kWXY5Xr2bT')"
        >
          <img
            src="https://cdn.bubbleplan.cn/static/home/dd.png"
            alt=""
            style="width: 42.12px; height: 39.11px; margin-right: 40px"
          />
        </div>
        <div
          class="bottom-title-text"
          style="display: inline-block"
          @click="skip('https://x.com/Bubble_Plan')"
        >
          <img
            src="https://cdn.bubbleplan.cn/static/home/X.png"
            alt=""
            style="width: 39.11px; height: 30.08px; margin-right: 0px"
          />
        </div>
      </div>
    </div>
    <div
      style="
        text-align: center;
        background: #10061e;
        width: 100%;
        padding-bottom: 30px;
        font-size: 12px;
        color: #ffffff;
      "
    >
      备案号：<a
        href="https://beian.miit.gov.cn/#/Integrated/index"
        target="_blank"
        style="color: #fff"
        >鄂ICP备2024062521号-1</a
      >
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {
    skip(url) {
      window.open(url);
    },
    contactus() {
      this.$router.push("/bottom/contactus");
    },
    problemFeedback() {
      this.$router.push("/bottom/problemFeedback");
    },
    recruit() {
      this.$router.push("/bottom/recruit");
    },
  },
};
</script>
<style lang="scss">
.bottom-title {
  width: 100%;
  // height: 342px;
  background: #10061e;
  padding-top: 98px;
  padding-bottom: 64px;
  &-title {
    color: #7e8087;
    font-size: 18px;
  }
  &-text:hover {
    cursor: pointer;
  }
  &-text {
    color: #ffffff;
    font-size: 20px;
    margin-top: 30px;
  }
}
</style>