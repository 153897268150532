var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"bottom-title flex flex-evenly"},[_vm._m(0),_c('div',[_c('div',{staticClass:"bottom-title-title"},[_vm._v("客服")]),_c('div',{staticClass:"bottom-title-text",on:{"click":function($event){return _vm.contactus()}}},[_vm._v("联系我们")]),_c('div',{staticClass:"bottom-title-text",on:{"click":function($event){return _vm.problemFeedback()}}},[_vm._v("问题反馈")])]),_c('div',[_c('div',{staticClass:"bottom-title-title"},[_vm._v("招聘")]),_c('div',{staticClass:"bottom-title-text",on:{"click":function($event){return _vm.recruit()}}},[_vm._v("相关")])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"bottom-title-text",staticStyle:{"display":"inline-block"},on:{"click":function($event){return _vm.skip(
            'https://www.douyin.com/user/MS4wLjABAAAAHzUqAlTLfiBKH7RYr6lfWFxMXP6NoqQcMo7gDrQsaTQ'
          )}}},[_c('img',{staticStyle:{"width":"39.11px","height":"39.11px","margin-right":"40px"},attrs:{"src":"https://cdn.bubbleplan.cn/static/home/dy.png","alt":""}})]),_c('div',{staticClass:"bottom-title-text",staticStyle:{"display":"inline-block"},on:{"click":function($event){return _vm.skip('https://weibo.com/u/7931073970')}}},[_c('img',{staticStyle:{"width":"39.11px","height":"30.08px","margin-right":"40px"},attrs:{"src":"https://cdn.bubbleplan.cn/static/home/wb.png","alt":""}})]),_c('div',{staticClass:"bottom-title-text",staticStyle:{"display":"inline-block"},on:{"click":function($event){return _vm.skip(
            'https://www.xiaohongshu.com/user/profile/668935d7000000000f03483d'
          )}}},[_c('img',{staticStyle:{"width":"36.1px","height":"36.1px","margin-right":"40px"},attrs:{"src":"https://cdn.bubbleplan.cn/static/home/xhs.png","alt":""}})]),_c('div',{staticClass:"bottom-title-text",staticStyle:{"display":"inline-block"},on:{"click":function($event){return _vm.skip('https://discord.gg/kWXY5Xr2bT')}}},[_c('img',{staticStyle:{"width":"42.12px","height":"39.11px","margin-right":"40px"},attrs:{"src":"https://cdn.bubbleplan.cn/static/home/dd.png","alt":""}})]),_c('div',{staticClass:"bottom-title-text",staticStyle:{"display":"inline-block"},on:{"click":function($event){return _vm.skip('https://x.com/Bubble_Plan')}}},[_c('img',{staticStyle:{"width":"39.11px","height":"30.08px","margin-right":"0px"},attrs:{"src":"https://cdn.bubbleplan.cn/static/home/X.png","alt":""}})])])]),_vm._m(1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex"},[_c('div',[_c('img',{staticStyle:{"width":"78px","height":"80px"},attrs:{"src":"https://cdn.bubbleplan.cn/static/home/logo.png","alt":""}})]),_c('div',{staticStyle:{"margin-left":"24px"}},[_c('div',{staticStyle:{"margin-top":"8px","color":"#ffffff","font-size":"21px","line-height":"29.75px"}},[_vm._v(" 泡泡计划 ")]),_c('div',{staticStyle:{"margin-top":"8px","color":"#999999","font-size":"19px","line-height":"26.92px"}},[_vm._v(" Bubble Plan ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"text-align":"center","background":"#10061e","width":"100%","padding-bottom":"30px","font-size":"12px","color":"#ffffff"}},[_vm._v(" 备案号："),_c('a',{staticStyle:{"color":"#fff"},attrs:{"href":"https://beian.miit.gov.cn/#/Integrated/index","target":"_blank"}},[_vm._v("鄂ICP备2024062521号-1")])])
}]

export { render, staticRenderFns }