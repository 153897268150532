<template>
  <div class="homew" @scroll="checkElementVisible">
    <div class="homew-carousel">
      <div>
        <el-carousel arrow="never" trigger="click">
          <el-carousel-item v-for="(item, index) in bannerList" :key="index">
            <img
              :src="item.img"
              alt=""
              style="width: 100%; height: 100%"
              @click="bannerButton(item)"
              class="homew-carousel-carousel"
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div
        class="homew-purchase"
        @click="commodityDetails()"
        v-if="product.isRecommend == 1"
      >
        <img :src="product.img" alt="" class="homew-purchase-img" />
      </div> -->
    </div>
    <div class="homew-carousel-homePage">
      <div class="homew-carousel-homePage-role">
        <div class="homew-carousel-homePage-role-title">
          {{ personList[initialIndex].name }}
        </div>
        <div class="homew-carousel-homePage-role-carousel">
          <el-carousel
            :interval="2000"
            type="card"
            indicator-position="none"
            :initial-index="initialIndex"
            @change="change"
            ref="carouse"
            arrow="never"
            :loop="true"
            :autoplay="true"
          >
            <SwiperItem v-for="(item, index) in personList" :key="index">
              <div style="position: relative">
                <div v-if="initialIndex != index" class="overlay"></div>
                <img
                  :src="initialIndex == index ? item.personImg : item.personBgc"
                  alt=""
                  class="homew-carousel-homePage-role-carousel-img"
                  :class="
                    initialIndex == index
                      ? 'matopw'
                      : 'homew-carousel-homePage-role-carousel-imgLeft'
                  "
                />
              </div>
            </SwiperItem>
          </el-carousel>
          <div class="flex homew-carousel-homePage-role-indicator">
            <div v-for="(item, index) in personList" :key="index">
              <img
                :src="item.personUser"
                alt=""
                @click="clickIam(index)"
                :class="
                  initialIndex == index
                    ? 'homew-carousel-homePage-role-indicator-img'
                    : 'homew-carousel-homePage-role-indicator-img1'
                "
              />
            </div>
          </div>
        </div>
      </div>

      <div class="homew-carousel-homePagewer">
        <div ref="myElement">
          <transition name="fade">
            <div v-if="show">
              <img
                src="https://cdn.bubbleplan.cn/static/home/Frame96262x.png"
                alt=""
                class="homew-carousel-homePagewer-img"
                @click="secondlevelButton('/home/activity')"
              />
            </div>
          </transition>
        </div>
        <div ref="myElements">
          <transition name="fade">
            <div v-if="showw" class="flex" style="justify-content: center">
              <img
                src="https://cdn.bubbleplan.cn/static/home/Frame96292x.png"
                alt=""
                class="homew-carousel-homePagewer-img1"
                @click="secondlevelButton('/gameRelated/gameIntroduction')"
              />
              <img
                src="https://cdn.bubbleplan.cn/static/home/Frame96302x.png"
                alt=""
                class="homew-carousel-homePagewer-img2"
                @click="secondlevelButton('/exchange/index')"
              />
            </div>
          </transition>
        </div>
      </div>
      <div class="homew-carousel-homePage-second">
        <div>
          <img
            src="https://cdn.bubbleplan.cn/static/home/Group13123101122x.png"
            alt=""
            style="width: 100%; height: 100%"
          />
        </div>
      </div>
      <div class="homew-carousel-homePage-scene">
        <el-carousel
          :interval="4000"
          type="card"
          :autoplay="true"
          arrow="always"
        >
          <el-carousel-item v-for="(item, index) in sceneList" :key="index">
            <img
              :src="item.img"
              alt=""
              class="homew-carousel-homePage-scene-img"
            />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="homew-carousel-homePage-title">视频展示</div>
      <div class="homew-carousel-homePage-vide">
        <div class="homew-carousel-homePage-vide-li">
          <img
            src="https://cdn.bubbleplan.cn/static/home/Group101112x.png"
            alt=""
            @click="playbackButton()"
            v-if="isPlayback"
            style="width: 100%; height: 100%"
          />
          <video
            width="100%"
            height="100%"
            controls
            poster="https://cdn.bubbleplan.cn/static/home/Group101112x.png"
            src="https://cdn.bubbleplan.cn/static/home/leftvideo.mp4"
            :ref="'videoPlayer0'"
            class="videow"
            @play="onPlay"
            @ended="onEnded"
            v-else
          ></video>
        </div>
      </div>

      <!-- <div class="homew-carousel-homePage-bottom flex">
        <img
          src="https://cdn.bubbleplan.cn/static/home/Group101152x.png"
          alt=""
          class="homew-carousel-homePage-bottom-left"
          @click="secondlevelButton('/dailyattendance/dailyattendance')"
        />
        <div>
          <img
            src="https://cdn.bubbleplan.cn/static/home/Group101132x.png"
            alt=""
            class="homew-carousel-homePage-bottom-img1"
            @click="secondlevelButton('/home/gamedownload')"
          />
          <img
            src="https://cdn.bubbleplan.cn/static/home/Group101142x.png"
            alt=""
            class="homew-carousel-homePage-bottom-img2"
            @click="secondlevelButton('/gameRelated/gameIntroduction')"
          />
        </div>
      </div> -->
    </div>
    <bottom></bottom>
  </div>
</template>
<script>
import bottom from "./components/bottom.vue";
import SwiperItem from "./components/Swiper.vue";
export default {
  components: {
    bottom,
    SwiperItem,
  },
  data() {
    return {
      productList: [],
      product: {},
      page: {
        pageNum: 1,
        pageSize: 10,
      },
      bannerList: [],
      isPlayback: true,
      sceneList: [
        {
          img: "https://cdn.bubbleplan.cn/static/home/underground.png",
        },
        {
          img: "https://cdn.bubbleplan.cn/static/home/petrolstation.png",
        },
        {
          img: "https://cdn.bubbleplan.cn/static/home/swimmingpool.png",
        },
        {
          img: "https://cdn.bubbleplan.cn/static/home/library.png",
        },
      ],
      personList: [
        {
          personImg: "https://cdn.bubbleplan.cn/m_bubble/person/A05.gif",
          personBgc: "https://cdn.bubbleplan.cn/m_bubble/home/503642x.png",
          name: "利奥",
          personUser:
            "https://cdn.bubbleplan.cn/m_bubble/home/Frame12396212x.png",
        },
        {
          personImg: "https://cdn.bubbleplan.cn/m_bubble/person/A03.gif",
          personBgc:
            "https://cdn.bubbleplan.cn/m_bubble/person/Group102932x.png",
          name: "菲力",
          personUser:
            "https://cdn.bubbleplan.cn/m_bubble/person/Frame6252x.png",
        },

        {
          personImg: "https://cdn.bubbleplan.cn/m_bubble/person/A01.gif",
          personBgc: "https://cdn.bubbleplan.cn/m_bubble/home/503622x.png",
          name: "洛奇",
          personUser:
            "https://cdn.bubbleplan.cn/m_bubble/person/Frame96222x.png",
        },
        {
          personImg: "https://cdn.bubbleplan.cn/m_bubble/person/A02.gif",
          personBgc:
            "https://cdn.bubbleplan.cn/m_bubble/person/Group102942x.png",
          name: "诺克",
          personUser:
            "https://cdn.bubbleplan.cn/m_bubble/person/Frame96252x.png",
        },
        {
          personImg: "https://cdn.bubbleplan.cn/m_bubble/person/A04.gif",
          personBgc: "https://cdn.bubbleplan.cn/m_bubble/home/503652x.png",
          name: "瑞瑟尔",
          personUser: "https://cdn.bubbleplan.cn/m_bubble/home/Frame96232x.png",
        },
      ],
      initialIndex: 2,
      show: false,
      showw: false,
    };
  },

  mounted() {
    this.systemBanner();
  },
  activated() {
    this.activityProductList();
  },
  methods: {
    checkElementVisible(event) {
      const myElement = this.$refs.myElement;
      const container = event.target;
      const containerRect = container.getBoundingClientRect();
      const elementRect = myElement.getBoundingClientRect();
      const isVisible =
        elementRect.top >= containerRect.top &&
        elementRect.bottom <= containerRect.bottom;
      if (isVisible) {
        this.show = true;
        // 元素在容器中可见
      } else {
        // 元素在容器中不可见
      }
      const myElements = this.$refs.myElements;
      const containers = event.target;
      const containerRects = containers.getBoundingClientRect();
      const elementRects = myElements.getBoundingClientRect();
      const isVisibles =
        elementRects.top >= containerRects.top &&
        elementRects.bottom <= containerRects.bottom;
      if (isVisibles) {
        this.showw = true;
        // 元素在容器中可见
      } else {
        // 元素在容器中不可见
      }
    },

    clickIam(index) {
      console.log(index);
      this.$refs.carouse.setActiveItem(index);
    },
    change(e, index) {
      this.initialIndex = e;
    },
    playbackButton() {
      this.isPlayback = false;
      setTimeout(() => {
        this.$refs.videoPlayer0.play();
      }, 50);
      // this.$refs.videoPlayer0.play();
    },
    onPlay() {
      this.isPlayback = false;

      // 处理播放开始
    },
    onEnded() {
      this.isPlayback = true;
      // 处理播放结束
    },
    secondlevelButton(url) {
      this.$router.push(url);
    },
    bannerButton(item) {
      if (item.crossAddr) {
        this.$router.push(item.crossAddr);
      }
    },
    async systemBanner() {
      const { data, code } = await this.$ajax.systemBanner({ type: 2 });
      this.bannerList = data.list;
    },
    commodityDetails() {
      // console.log(this.product);
      // return;
      this.$router.push({
        path: "/home/commodityDetails/commodityDetails",
        query: {
          ...this.product,
        },
      });
    },

    //获取首发列表
    async activityProductList() {
      const { data, code } = await this.$ajax.activityProductList({
        ...this.page,
      });
      this.productList = data.list;
      this.productList.forEach((item) => {
        if (item.isRecommend == 1) {
          this.product = item;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0;
}

:deep(.el-aside::-webkit-scrollbar) {
  width: 0px;
  height: 0px;
}
.homew::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.homew {
  width: 100%;
  height: calc(100vh - 94px);
  // margin-top: -103px;
  // background: #021521;
  overflow: auto;
  &-firstpublish {
    width: 100%;
    height: 762px;
  }

  :deep(.el-carousel__button) {
    width: 20px;
    height: 20px;
    border-radius: 87px;
    background: rgba(255, 255, 255);
  }
  :deep(.el-carousel__indicator.is-active button) {
    width: 20px;
    height: 20px;
    flex-shrink: 0;
    border-radius: 20px;
  }
  &-carousel {
    position: relative;
    &-carousel:hover {
      cursor: pointer;
    }
    &-carousel {
      max-height: 100%;
    }
  }
  &-purchase {
    position: absolute;
    top: 147px;
    right: 70px;
    z-index: 9999;
    &-img {
      width: 171px;
      height: 374px;
    }

    &-img:hover {
      cursor: pointer;
    }
    &-div {
      width: 237px;
      height: 282px;
      border: 6px solid #fff;
      border-radius: 6px;

      &-div {
        width: 226px;
        height: 270px;
        background: rgba(230, 239, 37, 1);
        border: 6px solid rgba(0, 0, 0, 1);
        border-radius: 6px;
        .product_img {
          width: 110px;
          height: 73px;
          margin-bottom: 29.5px;
          margin: auto;
          margin-top: 20px;
          margin-bottom: 20px;
        }
        .immediately {
          width: 145px;
          height: 51px;
        }
      }
    }
  }
}
.matopw {
  margin-top: 160px;
}
.overlay {
  width: 296px;
  height: 413px;
  transform: skew(-5deg);
  background: #00000073;
  z-index: 99;
  border-radius: 15px;
  position: absolute;
  left: 19px;
}
.homew-carousel-homePage {
  width: 100%;
  height: 4008px;
  background: url("https://cdn.bubbleplan.cn/static/home/home_bgw.jpg")
    no-repeat;
  background-size: 100% 100%;
  // padding-top: 25.3px;

  &-role {
    padding-top: 72px;
    &-title {
      margin: auto;
      width: 204px;
      height: 78px;
      line-height: 78px;
      text-align: center;
      color: #ffffff;
      font-family: "TsangerYuMo";
      font-weight: 800;
      font-size: 44px;
      background: url("https://cdn.bubbleplan.cn/m_bubble/home/Vector7182x.png")
        no-repeat;
      background-size: 100% 100%;
    }
    &-carousel {
      margin-top: 40px;

      &-img {
        // width: 406px;
        height: 500px;
      }
      &-imgLeft {
        width: 338px;
        height: 413px;
      }
      // :deep(.el-carousel__item) {
      //   width: calc(
      //     20% - 15px
      //   ); /* 每个卡片的宽度为总宽度的20%，减去的15px是卡片之间的间距 */
      //   margin-right: 15px; /* 卡片之间的间距 */
      // }
      :deep(.el-carousel__item--card) {
        width: 25%;
        display: flex;
        justify-content: center;
      }
      :deep(.el-carousel__mask) {
        background: transparent !important;
      }
      :deep(.el-carousel__button) {
        width: 95px;
        height: 103px;
        border-radius: 50%;
        margin: 0 20px;
      }
    }
    &-indicator {
      justify-content: center;
      align-items: center;
      margin-top: -82px;
      &-img:hover {
        cursor: pointer;
      }
      &-img1:hover {
        cursor: pointer;
      }
      &-img {
        width: 110px;
        height: 120px;
        margin: 0 21px;
      }
      &-img1 {
        width: 95px;
        height: 103px;
        margin: 0 21px;
      }
    }
  }
  .homew-carousel-homePagewer {
    margin-top: 124px;
    &-img:hover {
      cursor: pointer;
    }
    &-img1:hover {
      cursor: pointer;
    }
    &-img2:hover {
      cursor: pointer;
    }
    &-img {
      width: 1033px;
      height: 403px;
      margin: auto;
      margin-bottom: 10px;
    }
    &-img1 {
      width: 526px;
      height: 395px;
    }
    &-img2 {
      width: 520px;
      height: 395px;
    }
  }
  &-title {
    margin: auto;
    // margin-top: 25.3px;
    width: 396.83px;
    height: 215.8px;
    color: #ffffff;
    font-size: 50px;
    font-family: "qiantuhouheiti";
    background: url("https://cdn.bubbleplan.cn/static/home/Group100722x.png")
      no-repeat;
    background-size: 100% 100%;
    padding-top: 97.32px;
    padding-left: 53.17px;
  }
  &-vide {
    margin: auto;
    margin-top: -50px;
    width: 1201px;
    height: 738px;
    background: url("https://cdn.bubbleplan.cn/static/home/Group100902x.png")
      no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &-li {
      width: 807px;
      height: 545px;
      margin-top: 60px;
      img:hover {
        cursor: pointer;
      }
      video {
        background: #000;
      }
    }
  }
  &-second {
    width: 460px;
    height: 204px;
    margin-left: 270px;
    margin-top: 60px;
  }
  &-scene {
    width: 100%;
    height: 840px;
    margin-top: -50px;
    position: relative;
    &-img {
      width: 1048.41px;
      height: 671.3px;
    }
    :deep(.el-carousel__item--card) {
      width: 1048.41px !important;
    }
    :deep(.el-carousel__mask) {
      background-color: #000;
    }
    :deep(.el-carousel__arrow--right) {
      width: 80px;
      height: 80px;
      background: url("https://cdn.bubbleplan.cn/static/home/Group100862x.png")
        no-repeat;
      background-size: 100% 100%;
      left: 74%;
    }
    :deep(.el-carousel__arrow--left) {
      width: 80px;
      height: 80px;
      background: url("https://cdn.bubbleplan.cn/static/home/Group100872x.png")
        no-repeat;
      background-size: 100% 100%;
      left: 24%;
    }
    :deep(.el-icon-arrow-right) {
      display: none;
    }
    :deep(.el-icon-arrow-left) {
      display: none;
    }
    :deep(.el-carousel__button) {
      width: 17px;
      height: 17px;
      background: rgba(255, 255, 255, 0.5);
    }
    :deep(.el-carousel__indicators--outside button) {
      background: rgba(255, 255, 255, 0.5);
    }
    :deep(.el-carousel__indicators--outside) {
      // margin-top: -50px;
      position: absolute;
      bottom: 0px;
    }
    :deep(.is-active button) {
      background: #daff00 !important;
    }
  }
  &-bottom {
    padding: 0 60.56px;
    align-items: center;
    justify-content: center;
    img:hover {
      cursor: pointer;
    }
    &-left {
      width: 478px;
      height: 722px;
    }
    &-img1 {
      width: 805px;
      height: 345px;
    }
    &-img2 {
      width: 796px;
      height: 357px;
    }
  }
}

.homew-carousel-buutmImg {
  width: 100%;
  height: 619px;
  background: url("https://cdn.bubbleplan.cn/static/home/Videobottom.png")
    no-repeat;
  background-size: 100% 100%;

  &-video {
    width: 650px;
    height: 450px;
    margin-right: 21px;
    position: relative;
    &-playback {
      width: 68px;
      height: 68px;
      position: absolute;
      top: 40%;
      left: 46%;
      z-index: 99999;
    }
    &-playback img:hover {
      cursor: pointer;
    }
    &-playback:hover {
      cursor: pointer;
    }
  }
  &-img {
    width: 650px;
    height: 450px;
    margin-left: 21px;
  }
}
:deep(.el-carousel__container) {
  height: auto;
  height: 762px;
  // display: inline;
}
// :deep(.el-carousel__item):nth-child(1) {
//   display: contents;
// }
</style>